function Contact() {
  return (
    <div id="card">
      <div>
        <h1 id="contact">Contact</h1>
        <hr />
      </div>
      <h3>Email: edninodel@gmail.com</h3>
      <h3>Phone: (253)-509-2430</h3>
    </div>
  );
}
export default Contact;

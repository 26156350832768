function About() {
  return (
    <div id="card">
      <div>
        <h1 id="about">About</h1>
        <hr />
      </div>
      <div>
        <p>
        Hello! My name is Edwin and I am a student at the University of Washington studying Computer Science with a focus on software engineering. I am committed to utilizing my skills to further the mission of a company by setting and accomplishing clear, specific goals. In my free time I enjoy working on personal coding projects and spending time outside in the beautiful PNW.
        </p>
        <p>
        My coding journey has not been a straight path. Originally, I was a pre-dental student heavily studying biology and chemistry. I had never written a line of code before 2020. My life was changed during March of 2020 as COVID-19 caused my school education to be taken online. During this time, I decided to learn how to code out of curiosity as I saw Codecademy offering students 90 days of their premium subscription for free. I seized the opportunity and started to learn Python.
        </p>
        <p>Codecademy was great at first. I learned the basics of syntax and was spoon fed introductory concepts like control flow and basic data structures. However, there came a point in which I was tired of learning and itching to create. I decided I wanted to make a script that tracked the price of a product on Amazon each day and sent me a notification when the product was at a discount. I quickly realized that there were many things I did not know about programming in creating this project. I spent many days researching and teaching myself concepts such as browser automation, error handling, and REST API’s. Once completing the project, I realized that I could do much more than a simple price tracker.</p>
        <p>Since then, I have done various projects and have had many experiences involving software. With each of these experiences, I have continued to learn more each time.</p>
        <p class="bottom-paragraph">Overall, I love to solve problems. Code is simply a tool to solve problems in a technology focused world. The problems it can solve are fascinating and endless and I want to take part in the process.</p>
        <hr />
      </div>
    </div>
  );
}

export default About;

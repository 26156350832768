function WorkoutLogger() {
  return (
    <div>
      <h2>Workout Logger - A Better Workout App</h2>
      <p class="bottom-paragraph">
        At the start of the new year, I decided to make a change and start going to the gym on a consistent basis. I quickly discovered that keeping track of my workouts and progress was difficult. I tried out a few workout apps but every app I tried was either cumbersome to use or ineffective. Being a student with plenty of time, I created an app which allows me to keep track of my workouts with all the features I want. My app allows users to quickly log and keep track of their workouts while still being simple to use. One of the features I made sure to implement was the ability to add a link demonstrating each workout. This feature has saved me a lot of time since I will often forget how to do specific workouts. Click{" "}
        <a href="https://github.com/eninodel/workout_logger">here</a> to view the
        repository and click {" "} <a href="https://expo.dev/@enino/workout_logger">here</a> to download the app.
      </p>
      <hr />
    </div>
  );
}

export default WorkoutLogger;

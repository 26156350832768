function JamShare() {
    return (
      <div>
        <h2>JamShare - Instagram for Music</h2>
        <p class="bottom-paragraph">
            I love music. I grew up playing multiple instruments such as piano and alto sax. I listen to a lot of music as well. However, I have always felt that the current music platforms, while great for listening to music, are not so great for sharing good music with others. So, I created JamShare, a website that allows users to share music recommendations to others. It allows users to post, like, and comment  on other people's music recommendations. Additionally, it is integrated with Spotify so users can easily save any songs they like directly into their Spotify accounts. This project was my first full stack application. Check it out {" "}
          <a href="https://www.jam-share.com/">
            here
          </a>{" "}
  and click{" "}
          <a href="https://github.com/eninodel/JamShare">here</a> to view
          the repository.
            </p>
            <hr />
      </div>
    );
  }
  
  export default JamShare;
  